<template>
  <div class="registration">
    <BannerKv
      :titleEn="BannerKvData.titleEn"
      :titleZh="BannerKvData.titleZh"
      :backgroundPic="BannerKvData.backgroundPic"
    />

    <!-- <RegistrationStep :step="0" /> -->

    <section id="registration_choose">
      <div class="w1100">
        <h5 class="txt-bold txt-center">
          {{ $t("booking") }}
          <img src="@/assets/img/deco_flower.svg" alt="" class="deco_flower" />
        </h5>

        <div class="subject_box">
          <router-link
            :to="{
              name: 'RegistrationChoose',
              params: {
                classification: '1'
              },
              query: {
                type: 1
              }
            }"
            class="subject"
          >
            <img class="icon" src="@/assets/img/booking_1.svg" alt="" />
            <p class="txt-dark_gray">{{ $t("nuwaIvf") }}</p>
          </router-link>
          <router-link
            :to="{
              name: 'RegistrationChoose',
              params: {
                classification: '2'
              },
              query: {
                type: 1
              }
            }"
            class="subject"
          >
            <img class="icon" src="@/assets/img/booking_2.svg" alt="" />

            <p class="txt-dark_gray">{{ $t("nuwaTcm") }}</p>
          </router-link>
          <!-- <router-link to="/registrationchoose/2" class="subject">
            <img class="icon" src="@/assets/img/booking_2.svg" alt="">
            <p class="txt-dark_gray">{{$t('specialOutpatient')}}</p>
          </router-link> -->

          <!-- <router-link to="/registrationchoose/3" class="subject">
            <div class="icon" style="background-color: #E5A9B9;">
              <img src="@/assets/img/Online_Registration/icon1.svg" alt="">
            </div>
            <p class="txt-dark_gray">{{$t('nuwaIvfNew2')}}</p>
            <p class="txt-dark_gray" style="margin-bottom: -10px"
              v-if="getLanguage !== 'en'"
              >({{$t('ivf')}})</p>
          </router-link> -->
          <div class="subject" @click="() => openLightbox('specialClinic')">
            <div class="icon">
              <img src="@/assets/img/booking_1.svg" alt="" />
            </div>
            <p class="txt-dark_gray">{{ $t("specialOutpatient") }}</p>
          </div>
        </div>

        <p class="small txt-dark_gray txt-center">{{ $t("OutpatientInfo") }}</p>
      </div>
    </section>

    <section id="registration_note">
      <div class="w1000" v-if="apiI18nMessage" v-html="apiI18nMessage['掛號資訊說明']"></div>
      <!-- 改由後台設定 原文案搬去備份 -->
      <!-- <Note v-else></Note> -->
    </section>

    <!-- <div id="change_password_lightbox"
      v-show="changePasswordLightboxOpen">
      <div class="cover" @click="changePasswordLightboxOpen = false;"></div> -->
    <!-- 特約門診 -->
    <SpecialClinicDialog
      :show="specialClinic"
      :currLang="currLang"
      @close="() => closeLightbox('specialClinic')"
    />

    <!-- Covid19公告 -->
    <!-- <Covid19Dialog
      :show="covid19open"
      :currLang="currLang"
      @close="() => closeLightbox('covid19open')"
    /> -->

    <!-- 特別公告 -->

    <Dialog :show="dialogOpen" :currLang="currLang" @close="() => closeLightbox('dialogOpen')" />
  </div>
</template>

<script>
import '@/assets/scss/registration.scss';
import BannerKv from '@/components/BannerKv.vue';
// import RegistrationStep from '@/components/registration/RegistrationStep.vue';
import { mapState, mapGetters, mapMutations } from 'vuex';
import { getLangCode } from '@/utils/getLangCode';
import pic01 from '@/assets/img/index/imglink_1.jpg';
import Dialog from '@/components/registration/Dialog.vue';
// import registrationProhibitMixin from '@/registrationProhibitMixin';
import SpecialClinicDialog from '../components/registration/SpecialClinicDialog.vue';
// import Covid19Dialog from '../components/registration/Covid19Dialog.vue';
// import Note from '../components/registration/Note.vue';

export default {
  name: 'Registration',
  components: {
    BannerKv,
    // RegistrationStep,
    // Note
    SpecialClinicDialog,
    Dialog
    // Covid19Dialog
  },
  // mixins: [registrationProhibitMixin],
  data() {
    return {
      BannerKvData: {
        titleEn: 'ONLINE REGISTRATION',
        titleZh: this.$t('onlineBooking'),
        backgroundPic: pic01
      },
      specialClinic: false,
      // covid19open: false,
      dialogOpen: false
    };
  },

  computed: {
    ...mapState(['apiI18nMessage']),
    ...mapGetters(['getLanguage', 'showRegistrationDialog']),
    currLang() {
      return getLangCode();
    }
  },

  watch: {
    // showRegistrationDialog: {
    // handler(val) {
    //   this.dialogOpen = val;
    // },
    // immediate: true
    // },
    // showCovid19Dialog: {
    //   handler(val) {
    //     this.covid19open = val;
    //   },
    //   immediate: true
    // }
  },
  methods: {
    ...mapMutations([
      'logOut',
      // 'toggleShowVerifyWarning',
      'toggleShowPrivacyWarning',
      'toggleShowMsg',
      'setMsgInfo'
    ]),

    openLightbox(type) {
      // this.specialClinic = true;
      this[type] = true;
      const el = document.getElementsByTagName('body')[0];
      el.classList.add('lock');
    },
    closeLightbox(type) {
      this[type] = false;
      const el = document.getElementsByTagName('body')[0];
      el.classList.remove('lock');
    },
    // loginWarning() {
    //   this.toggleShowMsg(true);
    //   this.setMsgInfo({
    //     succeed: false,
    //     desc: this.$t('pleaseLogin'),
    //     callback: () => {
    //       this.$router.push({ name: 'Login' });
    //     },
    //     triggerCallbackOnOverlay: true,
    //     triggerCallbackOnConfirmBtn: true
    //   });
    // },
    // checkQualification() {
    //   const isLogin = this.$cookies.get('api_token');

    //   if (!isLogin) {
    //     this.loginWarning();
    //     return;
    //   }

    //   const { agree_privicy, email_verified_at, phone_verified_at } = this.memberInfo;

    //   // console.log('agree_privicy', agree_privicy);
    //   // console.log('email_verified_at', email_verified_at);
    //   // console.log('phone_verified_at', phone_verified_at);

    //   console.log(1, agree_privicy, 2, email_verified_at, 3, phone_verified_at);

    //   if (!agree_privicy) {
    //     this.toggleShowPrivacyWarning(true);

    //     return;
    //   }

    //   // 沒驗證 email 跳彈窗
    //   if (!email_verified_at) {
    //     this.toggleShowVerifyWarning({
    //       status: true,
    //       type: 'email',
    //       callback: () => {
    //         this.$router.push({ name: 'MemberData' });
    //       },
    //       triggerCallbackOnOverlay: true,
    //       triggerCallbackOnConfirmBtn: true
    //     });
    //     return;
    //   }

    //   // 沒驗證 手機 跳彈窗
    //   if (!phone_verified_at) {
    //     this.toggleShowVerifyWarning({
    //       status: true,
    //       type: 'phone',
    //       callback: () => {
    //         this.$router.push({ name: 'MemberData' });
    //       },
    //       triggerCallbackOnOverlay: true,
    //       triggerCallbackOnConfirmBtn: true
    //     });
    //     return;
    //   }

    //   this.dialogOpen = true;
    // }
  },
  created() {
    // this.checkQualification();
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/helper/_global.scss";
</style>

<template>
  <div id="registration_lightbox" v-show="show">
    <div class="cover" @click="closeLightbox"></div>

    <div class="box covid19__box">
      <img src="@/assets/img/delete.svg" class="light_box_close" @click="closeLightbox" />
      <div class="wrap">
        <div class="wrap__content">
          <div v-html="dialogContent"></div>
        </div>

        <div class="btn-wrap">
          <button type="button" class="btn p btn-wrap__understand" @click="closeLightbox">
            {{ $t("covid.closeButton") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // import VueCookies from 'vue-cookies';

export default {
  name: 'Dialog',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    currLang: String
  },
  watch: {
    show: {
      handler(val) {
        if (val) {
          const el = document.getElementsByTagName('body')[0];
          el.classList.add('lock');
        } else {
          const el = document.getElementsByTagName('body')[0];
          el.classList.remove('lock');
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      dialogContent: '',
      countLimit: 0
    };
  },
  methods: {
    closeLightbox() {
      this.$emit('close');
    },
    getContent() {
      // api 回來會存進去，若還沒資料會進入下面 if 遞迴，直到 api 資料回來
      const languageContent = localStorage.getItem('languages') || '';

      // 若 call 超過3秒都沒資料回來，停止遞迴
      if (this.countLimit >= 10) {
        return;
      }

      if (!languageContent) {
        this.countLimit += 1;
        setTimeout(() => {
          this.getContent();
        }, 300);
        return;
      }

      const content = JSON.parse(languageContent);

      this.dialogContent = content.registrationDialog;
    }
  },
  created() {
    this.getContent();
  }
};
</script>

<style lang="scss" scoped>
.wrap {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 10;

  &__content {
    height: 50vh;
    overflow-y: auto;
  }
}

.btn-wrap {
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
</style>

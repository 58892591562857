var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"registration"},[_c('BannerKv',{attrs:{"titleEn":_vm.BannerKvData.titleEn,"titleZh":_vm.BannerKvData.titleZh,"backgroundPic":_vm.BannerKvData.backgroundPic}}),_c('section',{attrs:{"id":"registration_choose"}},[_c('div',{staticClass:"w1100"},[_c('h5',{staticClass:"txt-bold txt-center"},[_vm._v(" "+_vm._s(_vm.$t("booking"))+" "),_c('img',{staticClass:"deco_flower",attrs:{"src":require("@/assets/img/deco_flower.svg"),"alt":""}})]),_c('div',{staticClass:"subject_box"},[_c('router-link',{staticClass:"subject",attrs:{"to":{
            name: 'RegistrationChoose',
            params: {
              classification: '1'
            },
            query: {
              type: 1
            }
          }}},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/img/booking_1.svg"),"alt":""}}),_c('p',{staticClass:"txt-dark_gray"},[_vm._v(_vm._s(_vm.$t("nuwaIvf")))])]),_c('router-link',{staticClass:"subject",attrs:{"to":{
            name: 'RegistrationChoose',
            params: {
              classification: '2'
            },
            query: {
              type: 1
            }
          }}},[_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/img/booking_2.svg"),"alt":""}}),_c('p',{staticClass:"txt-dark_gray"},[_vm._v(_vm._s(_vm.$t("nuwaTcm")))])]),_c('div',{staticClass:"subject",on:{"click":() => _vm.openLightbox('specialClinic')}},[_vm._m(0),_c('p',{staticClass:"txt-dark_gray"},[_vm._v(_vm._s(_vm.$t("specialOutpatient")))])])],1),_c('p',{staticClass:"small txt-dark_gray txt-center"},[_vm._v(_vm._s(_vm.$t("OutpatientInfo")))])])]),_c('section',{attrs:{"id":"registration_note"}},[(_vm.apiI18nMessage)?_c('div',{staticClass:"w1000",domProps:{"innerHTML":_vm._s(_vm.apiI18nMessage['掛號資訊說明'])}}):_vm._e()]),_c('SpecialClinicDialog',{attrs:{"show":_vm.specialClinic,"currLang":_vm.currLang},on:{"close":() => _vm.closeLightbox('specialClinic')}}),_c('Dialog',{attrs:{"show":_vm.dialogOpen,"currLang":_vm.currLang},on:{"close":() => _vm.closeLightbox('dialogOpen')}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/img/booking_1.svg"),"alt":""}})])
}]

export { render, staticRenderFns }
<template>
  <div id="registration_lightbox2"
    v-show="show">
    <div class="cover"
      @click="closeLightbox"></div>
    <div class="box">
      <img src="@/assets/img/delete.svg"
        class="close"
        @click="closeLightbox">
      <div class="top txt-center"
        :style="{backgroundImage: `url(${require('@/assets/img/temp/a_banner.jpg')})`}">
        <h5 class="txt-bold">{{$t('nuwaSpecialOutpatient')}}</h5>
        <h5>VIP Outpatient service <img class="deco_flower"
            src="@/assets/img/deco_flower.svg"
            alt=""></h5>
        <br>
        <p class="small">
          {{$t('nuwaSpecialOutpatientInfo_1')}}
          <!-- for future developer: 某天改了文案，但是中文變成一行字，英文仍然是兩行字 ... -->
          <template v-if="currLang.includes('en')">
            <br>
            {{$t('nuwaSpecialOutpatientInfo_2')}}
          </template>
        </p>
      </div>
      <div class="sec">
        <div class="inner">
          <div class="col40">
            <h5 class="txt-bold">
              {{$t('clinicServiceTitle')}}
              <img class="deco_flower"
                src="@/assets/img/deco_flower.svg"
                alt="">
            </h5>
          </div>
          <div class="col60">
            <p class="small">
              {{$t('clinicServiceContent')}}
            </p>
          </div>
        </div>
      </div>
      <div class="tri">
        <div class="inner">
          <div class="txt-center col100">
            <h5 class="txt-bold">
              {{$t('phoneBooking')}}
              <img class="deco_flower"
                src="@/assets/img/deco_flower.svg"
                alt="">
            </h5>
            <br>
            <p class="small">
              {{$t('phoneBookingContent')}}
            </p>
          </div>
        </div>
        <div class="stepouter">
          <div class="pic"
            :style="{backgroundImage: `url(${stepPic[nowstep-1]})`}"></div>
          <div class="stepbox">
            <div class="step"
              :class="{active : nowstep == 1}"
              @click="nowstep=1">
              <p class="small">{{$t('personReception')}}</p>
              <p class="in small">{{$t('personReceptionContent')}}</p>
            </div>
            <div class="step"
              :class="{active : nowstep == 2}"
              @click="nowstep=2">
              <p class="small">{{$t('vipSpace')}}</p>
              <p class="in small">{{$t('vipSpaceContent')}}</p>
            </div>
            <div class="step"
              :class="{active : nowstep == 3}"
              @click="nowstep=3">
              <p class="small">{{$t('accompany')}}</p>
              <p class="in small">{{$t('accompanyContent')}}</p>
            </div>
            <div class="step"
              :class="{active : nowstep == 4}"
              @click="nowstep=4">
              <p class="small">{{$t('personReception')}}</p>
              <p class="in small">{{$t('personReceptionContent_2')}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="four">
        <div class="inner">
          <div>
            <p class="small txt-bold">
              {{$t('clinicRule')}}
            </p>
            <ul class="small">
              <li>{{$t('clinicRuleContent1')}}</li>
              <li>{{$t('clinicRuleContent2')}}</li>
              <li>{{$t('clinicRuleContent3')}}</li>
              <li>{{$t('clinicRuleContent4')}}</li>
            </ul>
            <div class="line">
              <p class="small txt-bold">{{$t('specialClinicPhoneNumber')}}</p>
            </div>
            <p class="txt-center txt-theme">
              {{$t('local')}} (02)2706-6152 | {{$t('oversea')}} (886)2-2706-6152
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import qwe1 from '@/assets/img/temp/qwe1.jpg';
import qwe2 from '@/assets/img/temp/qwe2.jpg';
import qwe3 from '@/assets/img/temp/qwe3.jpg';
import qwe4 from '@/assets/img/temp/qwe4.jpg';

export default {
  name: 'SpecialClinicDialog',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    currLang: String
  },
  data() {
    return {
      stepPic: [qwe1, qwe2, qwe3, qwe4],
      nowstep: 1
    };
  },
  methods: {
    closeLightbox() {
      this.$emit('close');
    }
  }
};
</script>
